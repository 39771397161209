import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Bg from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/Footer"
import { ReactComponent as FB } from "../assets/facebook-violet.svg"
import { ReactComponent as IG } from "../assets/instagram.svg"
import { ReactComponent as TW } from "../assets/twitter-violet.svg"
import { ReactComponent as WEB } from "../assets/monitor.svg"
import Nav from "../components/Nav"
import waveUrl from "../assets/org-wave-big.svg"
import waveUrl2 from "../assets/org-wave-small.svg"
import { ReactComponent as Placeholder } from "../assets/placeholder.svg"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import "../styles/variables.css"

const OrgPageWrapper = styled.div`
  overflow: hidden;
  background: linear-gradient(180deg, #090836 30%, #7D2047, #090836);
  font-family: "Biko", sans-serif;

  @media screen and (min-width: 600px){
    background: linear-gradient(180deg,#14164f 30%,#7D2047,#090836);
  }

  nav {
    background-color: #7D2047;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.07em;
    color: var(--yellow);
    text-transform: uppercase;
  }

  h3 {
    font-size: 16px;
    letter-spacing: 0.04em;
    color: var(--yellow);
    line-height: 1.87;
  }
  header {
    position: relative;
    z-index: 20;
    padding: 20px 200px 0;
    margin-bottom: 56px;
    display: grid;
    grid-template-columns: auto 3fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "logo heading cta"
      "logo tagline cta"
      "logo category cta";
    grid-column-gap: 32px;
    #logo {
      grid-area: logo;
      align-self: start;
      justify-self: start;
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      background-image: url(${waveUrl});
      z-index: -10;
      width: 100%;
      top: -250px;
      height: 300px;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;
    }
    h1 {
      font-size: 31.25px;
      letter-spacing: 0.05em;
      color: var(--yellow);
      margin: 8px 0 18px;
      grid-area: heading;
    }

    p {
      grid-area: tagline;
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.05em;
      color: var(--white);
      margin-bottom: 24px;
    }

    .category-list {
      grid-area: category;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      /* justify-content: center; */

      .category {
        padding: 8px 16px;
        font-size: 16px;
        letter-spacing: 0.05em;
        border: 1px solid var(--magenta-100);
        border-radius: 5px;
        color: var(--white);
        margin-bottom: 16px;
        font-weight: 500;
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
    .cta {
      box-sizing: border-box;
      appearance: none;
      background: none;
      margin: 8px 0 0;
      grid-area: cta;
      align-self: start;
      justify-self: end;
      background: var(--magenta-100);
      border-radius: var(--btn-radius);
      a {
        width: 200px;
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: var(--white);
        font-weight: bold;
        box-sizing: border-box;
        &:hover {
          &:after {
            transform-origin: bottom left;
            transform: scaleX(0);
          }
        }
      }
    }
    @media screen and (max-width: 1300px) {
      padding: 0 175px;
    }
    @media screen and (max-width: 1200px) {
      padding: 0 150px;
      h1 {
        font-size: 28px;
      }
      p {
        font-size: 18px;
      }
    }
    @media screen and (max-width: 1100px) {
      padding: 0 100px;
      &::before {
        width: 120%;
        margin-left: -10%;
      }
    }
    @media screen and (max-width: 1040px) {
      padding: 0 50px;
      grid-column-gap: 16px;
      grid-template-columns: auto auto auto;
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
    @media screen and (max-width: 850px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      #logo {
        align-self: center;
      }
      .category-list {
        justify-content: center;
      }
      .cta {
        align-self: center;
        width: 70%;

        a {
          width: 100%;
        }
      }
      &::before {
        width: 150%;
        margin-left: -25%;
      }
    }
    @media screen and (max-width: 760px) {
      padding: 0 30px;
      h1 {
        font-size: 22px;
      }
    }
    @media screen and (max-width: 600px) {
      padding: 0 24px;
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
      &::before {
        width: 200%;
        margin-left: -40%;
        background-image: url(${waveUrl2});
      }
    }
    @media screen and (max-width: 500px) {
      padding: 24px 15px;
      .cta {
        width: 100%;

        a {
          width: 100%;
        }
      }
    }
  }

  .org-details {
    margin-bottom: 77px;
    padding-left: 200px;
    display: flex;
    align-items: center;
    h2 {
      margin-bottom: 10px;
    }
    .who-are-we {
      margin-bottom: 65px;
      p {
        max-width: 568px;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.04em;
        color: var(--white);
      }
    }

    .why-should-you-join-us {
      ul {
        max-width: 568px;
        line-height: 30px;
        letter-spacing: 0.04em;
        list-style: none;
        color: var(--white);
      }
    }

    .image-container {
      margin-left: auto;
      width: 556px;
      position: relative;
      svg {
        max-width: 100%;
      }
    }

    .org-text {
      margin-right: 64px;
    }

    @media screen and (max-width: 1000px) {
      padding-left: 120px;
    }

    @media screen and (max-width: 850px) {
      padding-left: 60px;
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
      .image-container {
        margin-left: -60px;
      }

      .why-should-you-join-us {
        margin-bottom: 64px;
      }
    }

    @media screen and (max-width: 620px) {
      .image-container {
        width: 100%;
        max-width: 556px;
      }

      svg,
      img {
        max-width: 100%;
      }
    }

    @media screen and (max-width: 520px) {
      padding-left: 30px;

      .image-container {
        margin-left: -30px;
      }
    }
  }

  .projects, .fees {
    display: flex;
    flex-direction: column;
    padding-left: 200px;
    padding-right: 200px;
    padding-bottom: 31px;
    background: none;

    @media screen and (max-width: 1000px) {
      padding-left: 120px;
      padding-right: 120px;
    }

    @media screen and (max-width: 850px) {
      padding-left: 60px;
      padding-right: 60px;
    }

    @media screen and (max-width: 630px) {
      padding-right: 60px;
    }

    @media screen and (max-width: 520px) {
      padding-right: 30px;
      padding-left: 30px;
    }
    h2 {
      margin-bottom: 24px;
    }

    p {
      font-size: 16px;
      line-height: 1.87;
      letter-spacing: 0.04em;
      max-width: 946px;
      color: var(--white);
    }

    .project {
      margin-bottom: 36px;
    }
  }

  .fees {
    margin-bottom: 20px;
  }

  footer {
    background: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 60vh;
    position: relative;
    text-align: center;
    padding: 0 110px;

    h1:first-child {
      font-size: var(--header-2);
      letter-spacing: 0.05em;
      color: var(--yellow);
      font-family: "The Nightmare", serif;
      /* or 175% */
      text-align: center;
      font-weight: 400;
      text-shadow: 0px 2px 0px #201A2C;
    }

    #connect {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.05em;
      color: var(--white);
      margin-bottom: 36px;
      text-align: left;
    }

    .link-container {
      display: flex;
      a:not(:last-child) {
        margin-right: 36px;
      }

      path {
        stroke: var(--white);
      }
    }

    .back {
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.05em;
      color: var(--white);
      text-decoration: none;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top: 32px;
      svg {
        margin-left: 18px;
        path {
          stroke: var(--white)
        };
      }
    }
    .cta {
      box-sizing: border-box;
      appearance: none;
      margin: 16px 0 32px;
      background: var(--magenta-100);
      border-radius: var(--btn-radius);
      a {
        width: 200px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #ffffff;
        font-weight: bold;
        box-sizing: border-box;
        &:hover {
          &:after {
            transform-origin: bottom left;
            transform: scaleX(0);
          }
        }
      }
    }
    @media screen and (max-width: 750px) {
      padding: 0 80px;
      height: 70vh;

      
    }
    @media screen and (max-width: 630px) {
      padding: 0 48px;
      align-items: center;
      .back {
        justify-content: center;
        margin-bottom: 40px;
      }
      .cta {
        width: 100%;

        a {
          width: 100%;
        }
      }
      #connect {
        text-align: center;
      }
    }
    @media screen and (max-width: 420px) {
      padding: 0 24px;
      p:first-child {
        font-size: 16px;
        line-height: 28px;
      }

      #connect {
        font-size: 12.8px;
        line-height: 20px;
      }

      .back {
        margin-top: 72px;
        position: static;
      }
    }
  }
`

const SBg = styled(Bg)`
  min-height: 60vh;
  background-image: cover;
  @media screen and (max-width: 700px) {
    margin-top: -10%;
  }
  @media screen and (max-width: 500px) {
    margin-top: -30%;
  }
`

const appendIfIncomplete = url => {
  if (!(url.startsWith("http") || url.startsWith("https"))) {
    return "https://" + url
  }

  return url
}

const capitalize = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}
export default ({ data }) => {
  const dataObj = data.prismicOrgPage.data

  const fullName = dataObj.org_fullname.text
  const orgRegForm = dataObj.org_registration_form.text
  const whoAreWe = dataObj.org_description.text
  const joinList = dataObj.org_why_join_list
  const tagline = dataObj.org_tagline.text
  const projectList = dataObj.org_project_list
  const socials = dataObj.org_socials
  const categories = dataObj.org_category_list
  const fees = dataObj.org_regfee
  const logo = dataObj.org_logo.localFile.childImageSharp.fixed

  const imgList = dataObj.org_images.filter(el => {
    if (el.org_image.localFile) {
      return true
    } else {
      return false
    }
  })

  let bgImage
  if (imgList.length >= 2) {
    bgImage = [
      imgList[0].org_image.localFile.childImageSharp.fluid,
    ].reverse()
  } else {
    bgImage = [
      `linear-gradient(180deg, #F77F23 0%, rgba(255, 209, 173, 0.34) 107.98%);`,
    ]
  }

  let sideImg
  if (imgList.length >= 2 || imgList.length === 1) {
    sideImg = imgList[1].org_image.localFile.childImageSharp.fluid
  } else {
    sideImg = "svg"
  }
  return (
    <Layout>
      <OrgPageWrapper>
        <Nav logo={data.logo}></Nav>
        <SEO title={fullName}></SEO>
        <SBg Tag="div" fluid={bgImage}></SBg>
        <header>
          <div id="logo">
            <Img fixed={logo} />
          </div>
          <h1 id="heading">{fullName}</h1>
          <p id="tagline">&ldquo;{tagline}&rdquo;</p>
          <div className="category-list">
            {categories.map(el => {
              return (
                <span className="category" key={el.org_category}>
                  {capitalize(el.org_category)}
                </span>
              )
            })}
          </div>
          {orgRegForm && (
            <div className="cta">
              <OutboundLink
                href={appendIfIncomplete(orgRegForm)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Join Now
              </OutboundLink>
            </div>
          )}
        </header>

        <div className="org-details">
          <div className="org-text">
            <div className="who-are-we">
              <h2>Who Are We</h2>
              <p>{whoAreWe}</p>
            </div>
            <div className="why-should-you-join-us">
              <h2>Why should you join us?</h2>
              <ul>
                {joinList.map(el => (
                  <li key={el.org_why_join.text}>✔ {el.org_why_join.text}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="image-container">
            {sideImg !== "svg" ? (
              <Img fluid={sideImg}></Img>
            ) : (
              <Placeholder></Placeholder>
            )}
          </div>
        </div>
        <div className="projects">
          <h2>Our Yearly Projects</h2>
          {projectList.map(el => {
            return (
              <div className="project" key={el.org_project_name.text}>
                <h3>{el.org_project_name.text}</h3>
                <p>{el.org_project_description.text}</p>
              </div>
            )
          })}
        </div>
        <div className="fees">
          <h2>Registration Fees</h2>
            {fees.map(el => {
              return (
                <div>
                  <p>✔ Regular: {el.org_regularfee.text}</p>
                  <p>✔ Scholar: {el.org_scholarfee.text}</p>
                </div>
            )
            })}
        </div>
        <footer>
          <h1>Sounds like we’re the right organization for you?</h1>
          {orgRegForm && (
            <div className="cta">
              <OutboundLink
                href={appendIfIncomplete(orgRegForm)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Join Now
              </OutboundLink>
            </div>
          )}
          <p id="connect">Connect with us on our social media account(s)!</p>
          {socials.map((el, i) => {
            return (
              <div className="link-container" key={i}>
                {el.org_facebook.text && el.org_facebook.text !== "none" && (
                  <OutboundLink href={appendIfIncomplete(el.org_facebook.text)}>
                    <FB />
                  </OutboundLink>
                )}
                {el.org_twitter.text && el.org_twitter.text !== "none" && (
                  <OutboundLink href={appendIfIncomplete(el.org_twitter.text)}>
                    <TW />
                  </OutboundLink>
                )}
                {el.org_ig.text && el.org_ig.text !== "none" && (
                  <OutboundLink href={appendIfIncomplete(el.org_ig.text)}>
                    <IG />
                  </OutboundLink>
                )}
                {el.org_website.text && el.org_website.text !== "none" && (
                  <OutboundLink href={appendIfIncomplete(el.org_website.text)}>
                    <WEB />
                  </OutboundLink>
                )}
              </div>
            )
          })}
          <Link to="/#organizations" className="back">
            BACK TO ORGANIZATIONS
            <svg
              width="23"
              height="18"
              viewBox="0 0 23 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 1.01872C15 1.0187 21.5 0.41872 21.5 6.01872C21.5 13.0187 16 12.0188 3 12.0188"
                stroke="#944100"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M7 8.0188L2 12.0188L7 16.5188"
                stroke="#944100"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </Link>
        </footer>
      </OrgPageWrapper>
      <Footer data={data} margin="-30%" style={{ zIndex: 999 }} />
    </Layout>
  )
}

// org_registration_form {
//   text
// }

export const query = graphql`
  query PrismicPageQuery($name: String!) {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 112) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    prismicOrgPage(
      data: { org_name: { raw: { elemMatch: { text: { eq: $name } } } } }
    ) {
      data {
        org_registration_form {
          text
        }
        org_category_list {
          org_category
        }
        org_description {
          text
        }
        org_fullname {
          text
        }
        org_images {
          org_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        org_logo {
          localFile {
            childImageSharp {
              fixed(width: 112, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        org_name {
          text
        }
        org_project_list {
          org_project_description {
            text
          }
          org_project_name {
            text
          }
        }
        org_socials {
          org_facebook {
            text
          }
          org_twitter {
            text
          }
          org_ig {
            text
          }
          org_website {
            text
          }
        }
        org_tagline {
          text
        }
        org_why_join_list {
          org_why_join {
            text
          }
        }
        org_regfee{
          org_scholarfee{
            text
          }
          org_regularfee{
            text
          }
        }
      }
    }
  }
`
